<template>
  <div class="how-to-choose-broker-menus" id="how-to-choose">
    <div class="container-broker-menu">
      <div class="how-to-choose-broker-menus-content">
        <div class="how-to-choose-broker-menus-header">
          <h3>How to choose the best broker?</h3>
        </div>
        <div class="how-to-choose-broker-menus-header-p">
          <p>
            Do not underestimate the power of a solid trading partner. There are
            a number of factors to consider when looking for a trusted broker.
          </p>
        </div>

        <div class="how-to-choose-broker-menus-cards">
          <div
            class="how-to-choose-broker-menus-card"
            v-for="(item, index) in cardData"
            :key="index"
          >
            <div class="how-to-choose-broker-menus-card-img">
              <img :src="item.img" alt="" />
            </div>

            <div class="how-to-choose-broker-menus-card-content">
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cardIcon1 from "../../assets/broker-menu/card-icon-1.svg";
import cardIcon2 from "../../assets/broker-menu/card-icon-2.svg";
import cardIcon3 from "../../assets/broker-menu/card-icon-3.svg";
import cardIcon4 from "../../assets/broker-menu/card-icon-4.svg";
import cardIcon5 from "../../assets/broker-menu/card-icon-5.svg";
import cardIcon6 from "../../assets/broker-menu/card-icon-6.svg";
export default {
  data() {
    return {
      cardData: [
        {
          img: cardIcon1,
          content:
            "Make sure your chosen broker is regulated. This gives you protection and a way to resolve disputes if they should arise.",
        },
        {
          img: cardIcon2,
          content:
            "Focus on trading conditions, which include low spreads and a reasonable leverage.",
        },
        {
          img: cardIcon3,
          content:
            "Look for a broker that offers enough assets across all trading types, Forex, Stocks, Indices, Commodities and Cryptos.",
        },
        {
          img: cardIcon4,
          content:
            "Speed and the quality of your trading platform can mean the difference between a successful trade or a loss. Make sure the broker has a range across mobile, desktop and web.",
        },
        {
          img: cardIcon5,
          content:
            "Customer support is key when working with a broker. Are they available when you need them? Are they efficient? How can you get in touch?",
        },
        {
          img: cardIcon6,
          content:
            "What additional tools does the broker offer? Do they have a social trading platform? Educational resources and market research?",
        },
      ],
    };
  },
};
</script>

<style>
.how-to-choose-broker-menus-cards {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 40px;
}
.how-to-choose-broker-menus-card {
  text-align: center;
  max-width: 390px;
  width: 100%;
  margin: 20px auto;
}
.how-to-choose-broker-menus-card img {
  margin: auto;
  margin-bottom: 15px;
}

.how-to-choose-broker-menus-card-content p {
  font-size: 21px;
}

.how-to-choose-broker-menus {
  margin-top: 30px;
}
.how-to-choose-broker-menus-header h3 {
  text-align: center;
  font-size: 46px;
  margin-bottom: 5px;
}

.how-to-choose-broker-menus-header-p {
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
}
.how-to-choose-broker-menus-header-p p {
  font-size: 21px;
  text-align: center;
}

@media (max-width: 1300px) {
  .how-to-choose-broker-menus-card-content p {
    font-size: 18px;
  }

  .how-to-choose-broker-menus-card {
    max-width: 350px;
  }
}
@media (max-width: 1150px) {
  .how-to-choose-broker-menus-card {
    max-width: 300px;
  }
}
@media (max-width: 1024px) {
  .how-to-choose-broker-menus-card {
    max-width: 232px;
  }

  .how-to-choose-broker-menus-header h3 {
    font-size: 30px;
  }

  .how-to-choose-broker-menus-header-p p {
    font-size: 18px;
    max-width: 788px;
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 920px) {
  .how-to-choose-broker-menus-card {
    max-width: 270px;
    margin: 20px 0;
  }

  .how-to-choose-broker-menus-cards {
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .how-to-choose-broker-menus-card-content p {
    font-size: 16px;
  }

  .how-to-choose-broker-menus-header-p p {
    font-size: 16px;
    max-width: 595px;
  }

  .how-to-choose-broker-menus-header h3 {
    font-size: 26px;
  }

  .how-to-choose-broker-menus-cards {
    margin-top: 12px;
  }
}

@media (max-width: 650px) {
  .how-to-choose-broker-menus-card {
    max-width: 200px;
  }
}
@media (max-width: 550px) {
  .how-to-choose-broker-menus-card {
    max-width: 190px;
  }
}
@media (max-width: 500px) {
  .how-to-choose-broker-menus-card {
    max-width: 320px;
    display: flex;
    text-align: left;
  }
  .how-to-choose-broker-menus-card img {
    margin-right: 15px;
  }
  .how-to-choose-broker-menus-cards {
    justify-content: center;
  }

  .how-to-choose-broker-menus-header h3 {
    font-size: 24px;
    margin-bottom: 8px;
  }
}
</style>
